import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import concat from 'lodash-es/concat';
import maxBy from 'lodash-es/maxBy';

import { apiClient } from 'api/ApiClient';
import type { EnvType, KeyDescription } from 'api/types';
import { allEnvTypes } from 'api/types';
import { useGetCompanySettings } from 'hooks/company';
import { useAuth } from 'shared/lib/auth/useAuth';

import type { Keys } from '../types';
import { CanCreateKey } from '../types';

type UseKeys = {
  isLoading: boolean;
  keys: Keys | undefined;
  refetch(): void;
};

type UseKeysParams = {
  refetchOnMount?: boolean | 'always';
};

export const useGetKeys = (params: UseKeysParams = {}): UseKeys => {
  const { user } = useAuth();
  const { data: company } = useGetCompanySettings();

  const isDeveloper = user?.profile?.role.id === 'developer';
  const skipProdKeys = isDeveloper && !company?.show_prod_keys_to_developers;

  const { data, isPending, refetch } = useQuery({
    queryKey: ['getKeys'],
    queryFn: () => apiClient.getUserKeys(),
    ...params,
    select: (data) =>
      skipProdKeys ? { dev: data.dev, sandbox: data.sandbox, prod: { ...data.prod, active_keys: [] } } : data,
  });

  return useMemo(() => {
    const allItems = concat(data?.dev.active_keys ?? [], data?.prod.active_keys ?? [], data?.sandbox.active_keys ?? []);

    return {
      isLoading: isPending,
      refetch,
      keys: data && {
        allItems,
        canCreate: allEnvTypes.reduceRight(
          (acc, env) => {
            if (data[env].can_create_new_key) {
              acc[env] = CanCreateKey.Yes;
            } else {
              acc[env] =
                data[env].active_keys.length === 0 ? CanCreateKey.NeedRequestFirstKey : CanCreateKey.NeedToDeleteOld;
            }

            return acc;
          },
          {} as Record<EnvType, CanCreateKey>,
        ),
        allKeys: allEnvTypes.reduceRight(
          (acc, env) => {
            acc[env] = data[env].active_keys;

            return acc;
          },
          {} as Record<EnvType, KeyDescription[]>,
        ),
        clientId: allItems?.[0]?.client_id,
        hasNewProdOrDevKeys:
          data.dev.active_keys.some((x) => x.last_used_at === null) ||
          data.prod.active_keys.some((x) => x.last_used_at === null),
        hasProductionKey: data.prod.active_keys.length > 0,
        hasDevelopmentKey: data.dev.active_keys.length > 0,
        hasSandboxKey: data.sandbox.active_keys.length > 0,
        sandbox: maxBy(data.sandbox.active_keys, (x) => x.created_at)?.access_key,
        development: maxBy(data.dev.active_keys, (x) => x.created_at)?.access_key,
        production: maxBy(data.prod.active_keys, (x) => x.created_at)?.access_key,
      },
    };
  }, [data, isPending, refetch]);
};
