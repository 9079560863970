import { endOfDay, startOfDay, subDays } from 'date-fns';

import type { EnvType, OrderFilterStatus, TaskStatus, TemplatesProductCombinations } from 'api/types';

export type Filters = {
  env: EnvType[];
  created_at__gte: string;
  created_at__lte: string;
  orderStatus?: OrderFilterStatus;
  taskStatus?: TaskStatus;
  responseStatus?: string;
  product_type?: string;
  provider?: string;
  products?: TemplatesProductCombinations;

  query?: string;

  page?: number;
  page_size?: number;
  ordering?: string;
};

const DEFAULT_PERIOD_DAYS = 30;

export const DEFAULT_PAGE_SIZE = 20;

export const defaultFilters: Filters = {
  env: ['sandbox'],
  created_at__gte: subDays(startOfDay(new Date()), DEFAULT_PERIOD_DAYS).toISOString(),
  created_at__lte: endOfDay(new Date()).toISOString(),
};
