import type { EnvType, KeyDescription } from 'api/types';

export enum CanCreateKey {
  Yes = 'yes',
  NeedRequestFirstKey = 'needRequestFirstKey',
  NeedToDeleteOld = 'needToDeleteOld',
}

export type Keys = {
  allItems: KeyDescription[];
  hasNewProdOrDevKeys: boolean;
  clientId?: string;
  sandbox?: string;
  development?: string;
  production?: string;
  hasProductionKey: boolean;
  hasDevelopmentKey: boolean;
  hasSandboxKey: boolean;
  allKeys: Record<EnvType, KeyDescription[]>;
  canCreate: Record<EnvType, CanCreateKey>;
};
